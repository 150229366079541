import './CostTool.css';
import React, {useContext} from "react";
import {useEffect, useState} from "react";
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Box, Button, CardActions, OutlinedInput} from "@mui/material"
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import FilterIcon from '@mui/icons-material/Filter';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {format} from 'date-fns';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventIcon from '@mui/icons-material/Event';
import TableViewIcon from '@mui/icons-material/TableView';
import DownloadIcon from '@mui/icons-material/Download';
import apiFilters from '../../api/filters';
import apiCosts from '../../api/costData';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import useSettings from '../../hooks/useSettings';
import useUser from '../../hooks/useUser';
import Paper from '@mui/material/Paper';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { AuthContext } from '../../contexts/authContext';
import { PieChart } from '@mui/x-charts/PieChart';
import {useMediaQuery} from "@mui/system";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {ALL as item} from "../../constants/general";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(quarterOfYear)

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            full: 1100
        },
    }
});


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#ffffff',
    border: '0px!important',
    boxShadow: 24,
    p: 4,
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const pascalCaseStr = (str) => {
    let words = str?.split(" ");

    for (let i = 0; i < words?.length; i++) {
        let word = words[i].toLowerCase();
        word = word.charAt(0).toUpperCase() + word.slice(1);
        words[i] = word;
    }

    return words?.join(" ");
}

const reformatCostsForDisplay = (cost) => {
    let newCost = cost.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
    if(newCost === '0.00') {
        newCost = '-';
    } else if (newCost.startsWith("-")) {
        newCost = `( ${newCost.replace("-", "$")} )`;
    } else {
        newCost = `$${newCost}`
    }
    return newCost
};

const getDisplayDate = (t) => {
    return dayjs(t).format("M/YYYY")
}

function getMuSelStyles(key, searchArr, theme) {
    return {
        fontWeight:
            searchArr.indexOf(key) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function DatePickerTool({name, setChange, disabled, value, minDate, className}) {
    return (
        <DatePicker className={className} label={name} value={value} disabled={disabled} onChange={(event) => setChange(event)} minDate={minDate} sx={{width: '100%'}}/>
    );
}
function SingleSelect({name, values, setChange, value, disabled}) {
    // if there is only one option, set it by default
    let singleDefaultVal;
    if(values.length) singleDefaultVal = values[0]?.id;
    if(values.length === 1 && value != singleDefaultVal) setChange(singleDefaultVal);

    return (
        <FormControl sx={{width: "100%"}}>
            <InputLabel id={`select-${name}`}>{name}</InputLabel>
            <Select
                labelId={`select-${name}`}
                id={`select-${name}`}
                value={value}
                label={name}
                disabled={disabled}
                onChange={(event) => setChange(event.target.value)}
            >
                {values.map((value) => {
                    return (
                        <MenuItem key={value.id} value={value.id}>
                            {value.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}


function SearchSelect({name, values, setChange, value, disabled,  inputValue, setInputValue}) {
    let options = values.map(v => {
            v.name = v.name.split(' ') // Split the string into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
            .join(' '); // Join the words back together

        return ({
            id: v.id,
            label: v.name
        })
    })

    // if there is only one option, set it by default
    if(options.length === 1 && value?.id !== options[0]?.id) {
        //console.log(" LOOPING HERE ? " , value)
        setChange(options[0]);
    }


    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                ////console.log(" NV ", newValue)
                setChange(newValue);
            }}
            inputValue={inputValue}
            disabled={disabled}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            id={name}
            options={options}
            renderInput={(params) => {
                return (<TextField {...params} label={name} ></TextField>);
            }}
        />
    )
}

function MultiSelect({name, values, setChange, value, disabled}) {
    const theme = useTheme();
    const oldValue = value;

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;

        const selAllVals = values.filter(v => v.id !== '*').map(v => v.id);

        if(oldValue.length && value.includes('*')) {
            setChange([])
        } else if(value.includes('*')) {
            setChange(selAllVals)

        } else {
            setChange(value)
        }
    };

    return (
        <FormControl sx={{width: "100%"}}>
            <InputLabel id={`select-${name}`}>{name}</InputLabel>
            <Select
                labelId={`select-${name}`}
                id={`eqTypeID`}
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput label={name} id={'something'}/>}
                MenuProps={MenuProps}
                disabled={disabled}
            >
                {values.map((val) => {
                    return (
                        <MenuItem
                            key={val.id}
                            value={val.id}
                            style={getMuSelStyles(val.name, values, theme)}
                        >
                            {val.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

const getQuarterlyDataForCostSummary = (data, equipId, quarterStartDate, quarterEndDate, startQuarter, endQuarter) => {
    const dataForCurrEquipId = data.filter(item => item.equipmentId === equipId);
    let marker = 0;
    let tempCost = 0;
    let quarterlyDataArr = [];

    dataForCurrEquipId.map((item, index) => {
        const dateInRange = dayjs(item.date).isSameOrAfter(quarterStartDate, 'day') && dayjs(item.date).isSameOrBefore(quarterEndDate, 'day');

        if (dateInRange) {
            if (marker < 2) {
                tempCost += item.cost;
                marker++;
            } else {
                tempCost += item.cost;
                const avgCost = tempCost / 3
                quarterlyDataArr.push({
                    alert: item.alert,
                    cost: avgCost
                });

                marker = 0;
                tempCost = 0;
            }
        }

    });

    return quarterlyDataArr.map((item, index) => {
        return (
            <div key={index} className='cost-tool-header-cell'>
                {reformatCostsForDisplay(item.cost)}
            </div>
        )
    });
}

function EquipmentCostModule({costsTableData, openRowIdArray, dataView, userType, quarterStartDate, quarterEndDate, startQuarter, endQuarter}) {
    const {costData, timeRangeLabels, quarterlyTimeRangeLabels, equipmentTypes, equipmentCostSummary, quarterlyEquipmentCostSummary, equipmentDateCostSummary} = costsTableData;

    return (
        equipmentTypes.map((equip, index) => {
            return (
                <div style={{display: 'inline'}} key={index}>
                    <div className='cost-tool-main-header'>
                        <div className={'cost-tool-header-cell'} style={{minWidth: '100%', color:'white'}}>
                            {equip.equipmentName}
                        </div>
                    </div>
                    <div className='cost-tool-header'>
                        { dataView ==='monthly' &&
                            timeRangeLabels.map((label, index) => {
                                return (
                                    <div key={index} className='cost-tool-header-cell'>
                                        {getDisplayDate(label)}
                                    </div>
                                )
                            })
                        }
                        { dataView ==='quarterly' &&
                            quarterlyTimeRangeLabels.map((label, index) => {
                                return (
                                    <div key={index} className='cost-tool-header-cell'>
                                        {label}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='cost-tool-header-summary'>
                        { dataView ==='monthly' ?
                            equipmentDateCostSummary.map((item, index) => {
                                if(item.equipmentId === equip.equipmentId)
                                    return (
                                        <div key={index} className={`cost-tool-header-cell ${(userType =='admin' && item.alert) ? ' cost-group-warning' : userType}`}>
                                            {reformatCostsForDisplay(item?.cost)}
                                        </div>
                                    )
                            })
                            :
                            getQuarterlyDataForCostSummary(equipmentDateCostSummary, equip.equipmentId, quarterStartDate, quarterEndDate, startQuarter, endQuarter)
                        }
                    </div>
                    {
                        costData.map((costItem, index) => {
                            let dataType;
                            dataView === 'monthly' ? dataType = equipmentCostSummary : dataType = quarterlyEquipmentCostSummary
                            const isOpen = (openRowIdArray.includes(costItem.costGroupId));

                            return <React.Fragment key={index}>
                                <div className='cost-tool-row'>
                                    {
                                        eval(dataType).map((item, index) => {
                                            if (item.equipmentId === equip.equipmentId && costItem.costGroupId === item.costGroupId) {
                                                return (
                                                    <div key={index}
                                                         title={'summaryRow costGroupID_' + costItem.costGroupId + ' dataView_' + dataView + ' equipmentID_' + item.equipmentId + ' date_' + (item.lastQrtDate || item.date)}
                                                         className={
                                                             ' cost-tool-cell-data cost-tool-cell-data-container-1' + ((userType ==='admin' && item.alert) ? ' cost-group-warning' : '')}>
                                                        {reformatCostsForDisplay(item.cost)}
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                {isOpen &&
                                    costItem?.breakdownItems.map((bdItem, index) => {
                                        let equipCostDataType;
                                        dataView ==='monthly' ? equipCostDataType = "equipmentCosts" : equipCostDataType = "quarterlyEquipmentCosts";

                                        const mappingItem = bdItem[equipCostDataType];

                                        return (
                                            <div className='cost-tool-row' key={index}>
                                                {
                                                    mappingItem.map((val, index) => {
                                                        if (val.equipmentId === equip.equipmentId) {

                                                            return (
                                                                <div key={index}
                                                                     title={'costGroupID_' + costItem.costGroupId + ' costGroupName_' + costItem.costGroupName + ' costItemId_' + bdItem?.breakdownId  + ' dataView_' + dataView + ' equipmentName_' + val.equipmentName + ' equipmentID_' + val.equipmentId + ' date_' + val.costDate}
                                                                     className={
                                                                         ' cost-tool-cell-data cost-tool-cell-data-container-1 ' +
                                                                         (reformatCostsForDisplay(val.cost) === '-' ? 'cost-tool-cell-empty' :
                                                                                 ((userType ==='admin' && val.alert)
                                                                                     ? 'cost-tool-cell-alert' : 'cost-group-detail-cell-data')
                                                                         )}
                                                                >
                                                                    {reformatCostsForDisplay(val.cost)}
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </React.Fragment>;
                        })
                    }
                </div>
            );
        })
    );
}

function CostItemRow({costItem}, hasParentID) {
    return (
        costItem.map((item, index) => {
            return (
                <div key={index} className='cost-tool-group-details-row cost-group-detail-name'>
                    <div className='cost-tool-cell-arrow '></div>
                    <div className='cost-tool-cell-cost-group-name'>
                        {(hasParentID && item.breakdownName === 'Street Trucking') ? `${item.breakdownName} *` : item.breakdownName}
                    </div>
                </div>
            );
        })
    );
}

function CostsTable({
                        costsTableData,
                        setopenRowIdArray,
                        openRowIdArray,
                        userType,
                        setDataView,
                        dataView,
                        canUseQuarters,
                        csvData,
                        tradelane,
                        filtersOpen,
                        setFiltersOpen,
                        setSelectedCostType,
                        selectedCostType,
                        origin,
                        destination,
                        quarterStartDate,
                        quarterEndDate,
                        startQuarter,
                        endQuarter,
                        hasParentID}) {
    const {costData} = costsTableData;



    return (
        <div className='cost-tool-table'>
            <div style={{display: 'inline'}}>
                <div className='cost-tool-main-header'>
                    <div className='cost-tool-cell-arrow'>
                        {!filtersOpen &&
                            <Tooltip title="Show Filters" arrow>
                                <IconButton
                                    aria-label="show filters"
                                    sx={{color: 'white', margin: '0', padding: '0'}}
                                    onClick={() => setFiltersOpen(!filtersOpen)}
                                >
                                    <FilterAltIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {filtersOpen &&
                            <Tooltip title="Show Data Summary" arrow>
                                <IconButton
                                    aria-label="show data summary"
                                    sx={{color: 'white', margin: '0', padding: '0'}}
                                    onClick={() => setFiltersOpen(!filtersOpen)}
                                >
                                    <TableViewIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                    <div className='cost-tool-cell-table-btn-header'>
                        {
                            (userType !== 'sales' &&
                                !(tradelane == 1 && [4,5].includes(origin?.id)) &&
                                !(tradelane == 2 && [14].includes(origin?.id))
                            ) &&
                            <>
                                <Tooltip title="Show All"  PopperProps={{
                                    sx: {
                                        marginTop: "-5px !important"
                                    },
                                }} arrow>
                                    <IconButton
                                        aria-label="show all costs"
                                        sx={{cursor: selectedCostType === 'allCosts' ? 'default' : 'pointer', color: selectedCostType === 'allCosts' ? 'white':'#7990bd', margin: '0', padding: '0', marginRight:'5px'}}
                                        onClick={() => {setSelectedCostType('allCosts')}}
                                    >
                                        <span style={{fontWeight: "bold"}}>A</span>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    PopperProps={{
                                        sx: {
                                            marginTop: "-5px !important"
                                        },
                                    }}
                                    title="Show Only Variable Costs"
                                    arrow>
                                    <IconButton
                                        aria-label="show only variable costs"
                                        sx={{cursor: selectedCostType === 'varCosts' ? 'default' : 'pointer', color: selectedCostType === 'varCosts' ? 'white':'#7990bd', margin: '0', padding: '0', marginRight:'5px'}}
                                        onClick={() => {setSelectedCostType('varCosts')}}
                                    >
                                        <span style={{fontWeight: "bold"}}>V</span>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    PopperProps={{
                                        sx: {
                                            marginTop: "-5px !important"
                                        },
                                    }}
                                    title="Show Only Fixed Costs" arrow>
                                    <IconButton
                                        aria-label="show only fixed costs"
                                        sx={{cursor: selectedCostType === 'fixCosts' ? 'default' : 'pointer', color: selectedCostType === 'fixCosts' ? 'white':'#7990bd', margin: '0', padding: '0', marginRight:'5px'}}
                                        onClick={() => {setSelectedCostType('fixCosts')}}
                                    >
                                        <span style={{fontWeight: "bold"}}>F</span>
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                        {
                            (
                                userType === 'sales' ||
                                (tradelane == 1 && [4,5].includes(origin?.id)) ||
                                (tradelane == 2 && [14].includes(origin?.id))) &&
                             <Tooltip
                                    PopperProps={{
                                        sx: {
                                            marginTop: "-5px !important"
                                        },
                                    }}
                                    title="Show Only Variable Costs"
                                    arrow>
                                    <IconButton
                                        aria-label="show filters"
                                        sx={{cursor: 'default', color: 'white', margin: '0', padding: '0', marginRight:'5px'}}
                                    >
                                        <span style={{fontWeight: "bold"}}>V</span>
                                    </IconButton>
                                </Tooltip>
                        }
                        {dataView ==='quarterly' &&
                            <Tooltip
                                PopperProps={{
                                    sx: {
                                        marginTop: "2px !important"
                                    },
                                }}
                                title="Show Monthly View" arrow>
                                <IconButton
                                    aria-label="show monthly view"
                                    sx={{color: 'white', padding: '0', margin: userType !== 'sales' ? '-2px 0 0 0 ' : '7px 5px 0 0'}}
                                    onClick={() => setDataView('monthly')}
                                >
                                    <CalendarMonthIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {(dataView ==='monthly' && canUseQuarters) &&
                            <Tooltip
                                PopperProps={{
                                    sx: {
                                        marginTop: "2px !important"
                                    },
                                }}
                                title="Show Quarterly View" arrow>
                                <IconButton
                                    aria-label="show quarterly view"
                                    sx={{color: 'white', padding: '0', margin: userType !== 'sales' ? '0' : '8px 5px 0 0'}}
                                    onClick={() => setDataView('quarterly')}
                                >
                                    <EventIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {userType !== 'sales' &&
                            <CSVLink
                                data={csvData}
                                filename={"cost-engine-data.csv"}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                <Tooltip
                                    PopperProps={{
                                        sx: {
                                            marginTop: "-7px !important"
                                        },
                                    }}
                                    title="Download CSV" arrow>
                                    <IconButton aria-label="download" sx={{color: 'white', marginTop: '1px', paddingLeft: '0'}}>
                                        <DownloadIcon fontSize="inherit"/>
                                    </IconButton>
                                </Tooltip>
                            </CSVLink>
                        }
                    </div>
                </div>
                <div className='cost-tool-header'>
                    <div className='cost-tool-cell-arrow'></div>
                    <div className='cost-tool-cell-cost-group-name'>
                        <span style={{fontWeight: 'bold'}}>Selected Time</span>
                    </div>
                </div>
                <div className='cost-tool-header-summary'>
                    <div className='cost-tool-cell-arrow'></div>
                    <div className='cost-tool-cell-cost-group-name'>
                        {dataView ==='quarterly' ? <span style={{fontWeight: 'bold'}}> Quarterly Cost Average</span> :  <span style={{fontWeight: 'bold'}}>Total</span> }
                    </div>
                </div>
                {
                    costData.map((item, index) => {
                        const isOpen = (openRowIdArray.includes(item.costGroupId));
                        return (
                            <React.Fragment key={index}>
                                <div className='cost-tool-row'>
                                    <div className='cost-tool-cell-arrow'
                                        title={'costGroupItem costGroupId_'+item?.costGroupId}
                                    >
                                        {item?.breakdownItems?.length > 0 &&
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={!isOpen ? () => setopenRowIdArray([...openRowIdArray, item.costGroupId]) : () => setopenRowIdArray(openRowIdArray.filter(n => n !== item.costGroupId))}
                                                style={{margin: '-5px 0 0 -5px'}}
                                            >
                                                {userType === 'admin' && (isOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>)}
                                            </IconButton>
                                        }
                                    </div>
                                    <div className='cost-tool-cell-cost-group-name'>
                                        {(hasParentID && item.costGroupName === 'Trucking Costs') ? `${item.costGroupName} *`: item.costGroupName}
                                    </div>
                                </div>
                                {isOpen && <CostItemRow costItem={item?.breakdownItems} hasParentID={hasParentID}/>}
                            </React.Fragment>
                        )
                    })}
            </div>
            <div style={{display: 'flex', overflowX: 'scroll'}}>
                {<EquipmentCostModule costsTableData={costsTableData} openRowIdArray={openRowIdArray} dataView={dataView} userType={userType} quarterStartDate={quarterStartDate} quarterEndDate={quarterEndDate} startQuarter={startQuarter} endQuarter={endQuarter}/>}
            </div>
        </div>
    );
}

function CostTool() {
    const {
        state: {globalUserType, isTestUser},
    } = useUser();


    const date = dayjs().add(1,'month');
    const tempStartDate = dayjs().set('year', new Date().getFullYear()).set('month', 0).set('date', 1);
    const tempEndDate = dayjs().set('year', new Date().getFullYear()).set('month', 11).set('date', 31);
    const [openRowIdArray, setopenRowIdArray] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [origin, setOrigin] = useState('');
    const [originIV, setOriginIV] = useState('');
    const [destinationIV, setDestinationIV] = useState('');
    const [destination, setDestination] = useState('');
    const [tradelane, setTradelane] = useState();
    const [startDate, setStartDate] = useState(dayjs(tempStartDate));
    const [endDate, setEndDate] = useState(tempEndDate);
    const [service, setService] = useState('');
    const [costsTableData, setCostsTableData] = useState([{data: 'true'}]);
    const [tradelaneNameData, setTradelaneNameData] = useState('');
    const [tradelaneData, setTradelaneData] = useState('');
    const [quarterStartDate, setQuarterStartDate] = useState('');
    const [quarterEndDate, setQuarterEndDate] = useState('');
    const [startQuarter, setStartQuarter] = useState('');
    const [endQuarter, setEndQuarter] = useState('');
    const [originData, setOriginData] = useState('');
    const [originDataAll, setOriginDataAll] = useState('');
    const [destinationData, setDestinationData] = useState('');
    const [destinationDataAll, setDestinationDataAll] = useState('');
    const [equipmentData, setEquipmentData] = useState([]);
    const [equipmentDataRaw, setEquipmentDataRaw] = useState([]);
    const [tradeMappingData, setTradeMappingData] = useState([]);
    const [tradeMapping, setTradeMapping] = useState([]);
    const [canUseQuarters, setCanUseQuarters] = useState(true);
    const [showSpinner, setShowSpinner] = useState(true);
    const [dataView, setDataView] = useState('monthly');
    const [serviceData, setServiceData] = useState('');
    const [servicesArr, setServicesArr] = useState([]);
    const [csvData, setCSVData] = useState([]);
    const [filterQueryDateErr, setFilterQueryDateErr] = useState(null);
    const [userType, setUserType] = useState('sales');
    const [modalOpen, setModalOpen] = React.useState(false);
    const [kffSelected, setKFFSelected] = React.useState('none');
    const [hasParentID, setHasParentID] = React.useState(false);
    const [oParentID, setOParentID] = React.useState(false);
    const [dParentID, setDParentID] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const authContext = useContext(AuthContext);

    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'), {
        defaultMatches: true
    });

    const largerThanMobile = useMediaQuery(theme.breakpoints.up('tablet'), {
        defaultMatches: true
    })

    const lessThanFull = useMediaQuery(theme.breakpoints.down('full'), {
        defaultMatches: true
    })
    const isTablet = largerThanMobile && lessThanFull
    const userData = authContext?.user;


    if((userData?.groups == "[\"CostEngine-Super\"]" || userData?.groups == "[\"CostEngine-Admin\"]") && userType !== 'admin' && !isTestUser)  setUserType('admin');

    const [selectedCostType, setSelectedCostType] = useState( 'allCosts');
    const [equipType, setEquipType] = useState([]);

    const isDisabled = !origin || !destination || !tradelane || !startDate || !endDate || !service || !equipType.length;
    const isResetDisabled = !tradelane;
    const { dispatch } = useSettings();

    console.log(" USER TYPE:  ", userType);
    console.log(" OKTA GROUP:  ", userData?.groups);

    const buildQuarterData = (data) => {
        let tempDBData = JSON.parse(JSON.stringify(data));
        const {timeRangeLabels} = tempDBData;
        const rawStartDate = timeRangeLabels[0];
        const rawEndDate = timeRangeLabels[timeRangeLabels.length - 1];
        const date1 = dayjs(rawStartDate).startOf('month');
        const date2 = dayjs(rawEndDate).endOf('month');

        let quartSDate = date1.startOf("quarter");
        let quartEDate = date2.endOf("quarter");
        let sQuarter = dayjs(rawStartDate).quarter();
        let eQuarter =dayjs(rawEndDate).quarter();


        if (date1.format('MM-DD-YYYY') !== quartSDate.format('MM-DD-YYYY')) {
            quartSDate = dayjs(rawStartDate).endOf("quarter").add(1, 'quarter').startOf("quarter");
            sQuarter = dayjs(rawStartDate).endOf("quarter").add(1, 'quarter').quarter();
        }

        if (date2.format('MM-DD-YYYY') !== quartEDate.format('MM-DD-YYYY')) {
            quartEDate = dayjs(rawEndDate).startOf("quarter").subtract(1, 'quarter').endOf('quarter');
            eQuarter = dayjs(rawEndDate).startOf("quarter").subtract(1, 'quarter').quarter();
        }

        const diffQuarters = quartEDate.add(1, 'day').diff(quartSDate, "quarter");

        setQuarterStartDate(quartSDate);
        setQuarterEndDate(quartEDate);
        setStartQuarter(sQuarter);
        setEndQuarter(eQuarter);

        // see if we have a full quarter of data
        // if we have a time range that includes quarters, create quarter data
        if (diffQuarters > 1) {
            // if(selectedCostType === 'allCosts' && (isStartOutSideOfQuarter || isEndOutSideOfQuarter))  {
            //     dispatch({
            //         type: 'triggeredAlert',
            //         data: {
            //             messageTitle: 'Quarterly Query Results',
            //             message: "Your query returned monthly data that falls outside of a standard quarterly format. Partial quarters will not be displayed. Please switch to the monthly view to see data for all months in your query, along with threshold data."
            //         }
            //     })
            // }
            setCanUseQuarters(true);

            // create Time Range Labels

            let timeRanLabArr = [];

            // merge equipmentCosts into quarters

            let marker = 0;
            let tempCost = 0;
            let costItemQtrSum = [];
            let quarterlyEquipmentCosts = [];
            let lastQtrDateArr = [];
            let dateInRange = false;

            tempDBData.costData.map(costData => {

                const bdItems = costData.breakdownItems.filter(bdItem => {
                    if(bdItem.breakdownId === 999) {
                        return true;
                    } else {
                        return bdItem.equipmentCosts.some(e => e.cost != 0);
                    }
                });
                costData.breakdownItems = bdItems;
                costData.breakdownItems.map(bdItem => {
                    quarterlyEquipmentCosts = [];

                    bdItem.equipmentCosts.map(ec => {

                        dateInRange = dayjs(ec.costDate).isSameOrAfter(quartSDate, 'day') && dayjs(ec.costDate).isSameOrBefore(quartEDate, 'day');
                        if (dateInRange) {
                            if (marker < 2) {
                                tempCost += ec.cost;
                                marker++;
                            } else {
                                tempCost += ec.cost;
                                const avgCost = tempCost / 3
                                quarterlyEquipmentCosts.push({
                                    equipmentId: ec.equipmentId,
                                    equipmentName: ec.equipmentName,
                                    cost: avgCost
                                });

                                let rangeLabel = `Q${dayjs(ec.costDate).quarter()} ${dayjs(ec.costDate).format('YYYY')}`;

                                if(timeRanLabArr.indexOf(rangeLabel) == -1) timeRanLabArr.push(rangeLabel);


                                if (avgCost) {
                                    costItemQtrSum.push({
                                        equipmentId: ec.equipmentId,
                                        costGroupId: costData.costGroupId,
                                        breakdownId: bdItem.breakdownId,
                                        lastDate: ec.costDate,
                                        cost: avgCost
                                    })
                                }

                                if(lastQtrDateArr.indexOf(ec.costDate) === -1) lastQtrDateArr.push(ec.costDate)

                                marker = 0;
                                tempCost = 0;
                            }
                        }
                    })
                    tempDBData.quarterlyTimeRangeLabels = timeRanLabArr;
                    bdItem.quarterlyEquipmentCosts = quarterlyEquipmentCosts;
                    return bdItem;
                })
            });

            // get averages from calculated cost quarters, and create summaries for cost group type
            let costGroupAverages = [];
            const {costGroups, equipmentTypes} = tempDBData;
            marker = 0;
            tempCost = 0;
            costGroupAverages = [];

            equipmentTypes.map(et => {
                costGroups.map(cg => {
                    lastQtrDateArr.map(dat => {
                        const equipId = et.equipmentId;
                        const cgId = cg.costGroupId;

                        const filterdAndSummed = costItemQtrSum.filter(item => {
                            return item.costGroupId == cgId
                                && item.equipmentId == equipId
                                && item.lastDate == dat;
                        });

                        if (filterdAndSummed.length) {
                            const costItemAverageByQtr = filterdAndSummed.reduce((a, b) => ({cost: a.cost + b.cost})).cost / filterdAndSummed.length;

                            let tempCost = 0;
                            const costTest = filterdAndSummed.map(fs => {
                                tempCost += fs.cost
                            })

                            costGroupAverages.push({
                                cost: tempCost,
                                equipmentId: equipId,
                                costGroupId: cgId,
                                lastQrtDate: dat
                            })
                        }
                    })
                })
            })

            if (costGroupAverages.length) tempDBData.quarterlyEquipmentCostSummary = costGroupAverages;

            // auto set table to show Quarters if time selected is greater than 6 months
            if (diffQuarters > 1) {
                setDataView('quarterly');
            }


        } else {
            setCanUseQuarters(false);
        }
        setCostsTableData(tempDBData);
        window.costDataRaw = JSON.stringify(tempDBData);
    }

    const getCostTypes = (n) => {
        if (n === 1) return 'fixed';
        if (n === 2) return 'variable';
    }

    const buildCSVData = () => {

        let returnData = [];

        const {equipmentTypes, timeRangeLabels, equipmentCostSummary, costGroups, costData} = costsTableData;

        // build header row
        let row = equipmentTypes.map(equip=>{
            return timeRangeLabels.map(time=>{
                return `${equip.equipmentName}: ${getDisplayDate(time)}`
            })
        })

        const headerRow = row.flat(2);
        headerRow.unshift("Cost Type");
        headerRow.unshift("Cost Group");
        returnData.push(headerRow);

        // populate body of csv with data:
        let usableCostGroupIdsArr = [];
        costGroups.map(cg => {
            let costRow = equipmentCostSummary.filter(equip => {
                if(!usableCostGroupIdsArr.includes(equip.costGroupId)) usableCostGroupIdsArr.push(equip.costGroupId)
                return equip.costGroupId === cg.costGroupId
            }).map(item => item.cost);

            costRow.unshift("cost group");

            if(usableCostGroupIdsArr.includes(cg.costGroupId)){
                // not all cost groups in the cost group array will
                // be used on every query based on which filters are selected


                costRow.unshift(cg.costGroupName);
                returnData.push(costRow);

                costData.map(cd => {
                    if(cd.costGroupId === cg.costGroupId) {
                        const breakPoint = cd.breakdownItems.length-1;
                        cd.breakdownItems.map((bdItems, index) => {
                            costRow = bdItems.equipmentCosts.map(eqCosts => eqCosts.cost);
                            costRow.unshift(getCostTypes(bdItems.costType));
                            costRow.unshift(bdItems.breakdownName);
                            // preventing odd dupe issue on last item
                            if(index < breakPoint) returnData.push(costRow);
                        })
                    }
                });
                returnData.push(costRow);
            }

        });
        setCSVData(returnData);
    };

    const getVanityEqupNames = (id) => {
       ////console.log(" DFFD ", id)
        return equipmentDataRaw.find(x => x.ID === id).WorkTagID;
    };

    const validateOriginAndDestinationData = (data) => {
        for (let i = 0; i < data.length; i++) {
            data[i].name=pascalCaseStr(data[i].name)
        }

        data.sort((a, b) => {
            // Convert both values to lowercase for case-insensitive sorting
            let valueA = a.name.toLowerCase();
            let valueB = b.name.toLowerCase();

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        });

        return data;
    }

    const fetchFilterData = () => {

        apiFilters.get().then(
            (data) => {
                const tradelaneNameData = validateOriginAndDestinationData(data.tradelaneNames);
                const tradelaneData = validateOriginAndDestinationData(data.origins);
                const originData = validateOriginAndDestinationData(data.origins);
                const originDataAll = validateOriginAndDestinationData(data.origins);
                const destinationData = validateOriginAndDestinationData(data.destinations);
                const destinationDataAll = data.destinations;
                const tradeMappingData = data.tradeMapping;
                const serviceData = data.services;
                const service = serviceData.length === 1 ? serviceData[0].id : '';
                const equipmentDataRaw = data.equipment;

                // used for playwright testing
                const winVars =  {
                    tradelaneNameData,
                    tradelaneData,
                    originData,
                    originDataAll,
                    destinationData,
                    destinationDataAll,
                    tradeMappingData,
                    serviceData,
                    service,
                    equipmentDataRaw
                };

                window.filterVars = JSON.stringify(winVars);

                setTradelaneNameData(tradelaneNameData);
                setTradelaneData(tradelaneData);
                setOriginData(originData);
                setOriginDataAll(originDataAll);
                setDestinationData(destinationData);
                setDestinationDataAll(destinationDataAll);
                setTradeMappingData(tradeMappingData);
                setServiceData(serviceData);
                setService(service)
                setEquipmentDataRaw(equipmentDataRaw);

                setShowSpinner(false);
                dispatch({type: 'addAppVersion', data: data.versions})
            }
        ).catch((err) => {
            setShowSpinner(false);
            setFiltersOpen(true);
            dispatch({type: 'triggeredAlert', data: {
                    statusType: 'warning',
                    error: err?.message,
                    messageTitle: 'Query Error',
                    message: 'We are having a temporary issue getting your filter data.  Please hit refresh on your browser and try again.  If you continue to see this message, please email 1Costengine@matson.com'
                }})
        });
    }

    const fetchCostData = () => {
        console.log(" FETCHING COST DATA")
        setShowSpinner(true);
        setDataView('monthly');
        let costType=0;

        if (selectedCostType === 'fixCosts') costType = 1;
        if (selectedCostType === 'varCosts') costType = 2;
        if (userType === 'sales') costType = 2;

        let oParentID = 0;
        let dParentID = 0;
        if( originData.length && origin && destinationData.length && destination) {
             oParentID = originData.filter(o => o.id === origin.id)[0]?.parentId;
             dParentID = destinationData.filter(o => o.id === destination.id)[0]?.parentId;
        }

        setOParentID(oParentID);
        setDParentID(dParentID);

        const sendData = {
            tradelane,
            origin: origin.id,
            destination: destination.id,
            equipType,
            startDate,
            endDate,
            service,
            costType
        };

        apiCosts.post(sendData).then(
            (data) => {
                setFilterQueryDateErr(null)
                console.log(" GET COST RETURN DATA ", data)
                // ---> FOR AK TRADE, NEED TO DOUBLE Dutch Harbor Wharfage for AKUTAN - no way to do
                //      this in the Venna input, as AKUTAN has no cost center code in data
                //      breakdown ID is 50

                setShowSpinner(false);

                if(data?.message?.type === 'warning') {
                    setFiltersOpen(true);
                    setCostsTableData([])
                    setFilterQueryDateErr(data?.message?.warningMess)
                } else {
                    buildQuarterData(data);
                    if(oParentID || dParentID){
                        setHasParentID(true);
                        //if(selectedCostType === 'allCosts')
                        handleModalOpen();
                    } else {
                        setHasParentID(false);
                    }
                }

            },
        ).catch((err) => {
            setShowSpinner(false);
            setFiltersOpen(true);
            dispatch({type: 'triggeredAlert', data: {
                    statusType: 'warning',
                    error: err,
                    messageTitle: 'Query Error',
                    message: 'There was an issue with your query.  Please try again.  If you continue to see this message, please email 1Costengine@matson.com'
                }})
        });
    }

    const handleReset = () => {
        setTradelane('');
        setOrigin('');
        setDestination('');
        setEquipType([]);
        setHasParentID(false);
        setDParentID(null);
        setOParentID(null);
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        setService('');
        setCostsTableData([]);
        setopenRowIdArray([]);
        setShowSpinner(true)
        setTimeout(() => {
            fetchFilterData();
        }, "2000");

    }

    const handleFilterClick = () => {
        if(userType === 'sales') setSelectedCostType('varCosts');

        setFiltersOpen(!filtersOpen);
        fetchCostData();
    };

    const getDisplayValuesByID = (type, id) => {
        let returnVal = '';
       ////console.log(" DFS  ", type, id)
        if(Array.isArray(id)) id = id[0].id

        switch (type) {
            case 'equipment':
                returnVal = equipmentData.find(x => x.id === id)?.name;
                break;
            case 'tradelanes':
                returnVal = tradelaneNameData.find(x => x.id === id)?.name;
                break;
            case 'services':
                returnVal = serviceData.find(x => x.id === id)?.name;
                break;
            case 'origins':
                returnVal = id.label;
                break;
            case 'destinations':
                returnVal = id.label;
                break;
        }
        return returnVal;
    }

    const updateAvailEqTypes = () => {
        // KODIAK AND OTHER DEST do not get D45 or I45
        const noD45Arr = [101];
        const noI45Arr = [19, 22, 97, 100, 95, 45, 102, 99, 101];

        if (equipmentDataRaw) {
            let eData = [];
            equipmentDataRaw.forEach(eq => {
                let tradeArray = [];
                if (eq?.CTTradelaneID?.indexOf(',') > -1) tradeArray = eq.CTTradelaneID.split(',');
                if (tradeArray.includes(tradelane.toString()) || eq.CTTradelaneID == tradelane) eData.push({
                    id: eq.id,
                    name: eq.name
                })
            });

            // hide D20 for non admin users, hide I45 for some origin/destinations on AK tradelane only

            if (userType === 'sales' && tradelane === 6) eData = eData.filter(e => e.name !== 'Dry-20')

            if ((destination?.id && noI45Arr.indexOf(destination?.id) > -1) || (tradelane == 6 && (service == 4 || service == 5))) {
                eData = eData.filter(e => e.id !== 45);
            }
            if (destination?.id && noD45Arr.indexOf(destination?.id) > -1) {
                eData = eData.filter(e => e.id !== 3);
            }
            setEquipType([]);
            setEquipmentData([{name: 'SELECT ALL', id: '*'}, ...eData]);
        }
    }

    const getCostData = () => {
        return costsTableData
    }

    useEffect(()=> {
        if(serviceData.length) {
            const servicesForTradeLane = serviceData.filter(d => d.tradelaneID === tradelane);
            setServicesArr(servicesForTradeLane)
        }
        updateAvailEqTypes();

    }, [tradelane])

    useEffect(() => {
        setOrigin('');
        setDestination('');
        setOriginIV('');
        setDestinationIV('');
        //console.log(" SERVICE ", service)
        const tradeMap = tradeMappingData.filter(t => {
            return t.serviceID === service;
        })

        setTradeMapping(tradeMap);

        const origins = tradeMap.map(m => {
            return {id: m.originID, name: m.originName, parentId: m?.oParentID || 0}
        })



        let uniqueOrigins = origins.reduce((accumulator, current) => {
            // Check if there's no object with the same stringified version already in the accumulator
            if (!accumulator.find(obj => JSON.stringify(obj) === JSON.stringify(current))) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        setOriginData(validateOriginAndDestinationData(uniqueOrigins));

    }, [service]);

    useEffect(() => {
        setDestination('');
        setDestinationIV('');
        const tradeMap = tradeMappingData.filter(t => {
            return t.serviceID === service && t?.originID === origin?.id;
        })

        const destinations = tradeMap.map(m => {
           ////console.log(" THE m's? ", m)
            return {id: m.destinationID, name: m.destinationName, parentId: m?.dParentID || 0}
        })

        setDestinationData(validateOriginAndDestinationData(destinations));
        updateAvailEqTypes();
    }, [origin]);

    useEffect(() => {
        updateAvailEqTypes();
    }, [destination]);

    useEffect(() => {
       ////console.log(" DSFSDDDDDDDDDDDDDDDDDD  ", equipType)
        let notKFF = 0;
        let foundKFF =  0;
        equipType?.forEach(eqT =>
        {
            if (eqT.id !== '*') equipmentData?.find(x => x.id === eqT)?.name?.indexOf('KFF') > -1 ? foundKFF++ : notKFF++;
        });

        if(!notKFF && foundKFF) setKFFSelected('KFF')
        if(notKFF && foundKFF) setKFFSelected('SOME');
        if(notKFF && !foundKFF) setKFFSelected('NONE');
    }, [equipType]);

    useEffect(() => {
        console.log(" UT ", globalUserType, isTestUser)
        if( isTestUser ) {
            setUserType(globalUserType);
        }
    }, [globalUserType, isTestUser]);

    useEffect(() => {
        if(costsTableData?.costData?.length > 0) buildCSVData();
    }, [costsTableData]);

    useEffect(() => {
        if(costsTableData?.costData?.length > 0) fetchCostData();
    },[selectedCostType]);

    useEffect( () => {
        if(userType === 'sales') {
            setSelectedCostType('varCosts');
            setEquipType([]);
        }
        if(userType === 'admin') {
            setSelectedCostType('allCosts');
            // --> setEquipType([1,2,3,4,5])
        }


    },[userType]);

    useEffect(() => {
        fetchFilterData();
        window.getCostData = getCostData;
    }, []);

    console.log(" ORIGIN DATA ", originData?.length)

    if(showSpinner){
        return (<Box sx={{position: 'absolute', left: '50%'}}>
            <CircularProgress/>
        </Box>)
    } else if (originData?.length && !showSpinner) {
        return (
            <ThemeProvider theme={theme}  >
                <React.Fragment>
                    { filtersOpen &&
                        <Card sx={{
                            minWidth: (isMobile || isTablet) ? '100%' : 300,
                            minHeight: 210,
                            border: "1px solid rgba(211,211,211,0.6)",
                            borderRadius: 2,
                            margin: '0 12px 20px 0',
                        }}>
                            <CardHeader
                            ></CardHeader>
                            <div style={{
                                height: 'auto',
                                backgroundColor: "white"
                            }}>
                                <CardContent sx={{padding: "0!important"}} >
                                    <Grid container spacing={3}
                                          sx={{
                                              width: "100%",
                                              margin: 0,
                                              padding: "10px 25px 10px 15px",
                                              position: "relative",
                                              top: "-20px"
                                          }}>
                                        <Grid mobile={12} tablet={6} full={3}>
                                            <SingleSelect name="Tradelanes"
                                                          values={tradelaneNameData}
                                                          setChange={setTradelane}
                                                          value={tradelane}/>
                                        </Grid>
                                        <Grid mobile={12} tablet={6} full={3}>
                                            <SingleSelect name="Services"
                                                          values={servicesArr}
                                                          setChange={setService}
                                                          value={service}
                                                          disabled={!tradelane || tradelane === null}
                                            />
                                        </Grid>
                                        <Grid mobile={12} tablet={6} full={3}>
                                            <SearchSelect name="Origin"
                                                          values={originData}
                                                          setChange={setOrigin}
                                                          value={origin}
                                                          disabled={!service || false}
                                                          inputValue={originIV}
                                                          setInputValue={setOriginIV}
                                            />
                                        </Grid>
                                        <Grid mobile={12} tablet={6} full={3}>
                                            <SearchSelect name="Destination"
                                                          values={destinationData}
                                                          setChange={setDestination}
                                                          value={destination}
                                                          disabled={!origin || false}
                                                          inputValue={destinationIV}
                                                          setInputValue={setDestinationIV}
                                            />
                                        </Grid>
                                        <Grid mobile={12} tablet={6} full={3}>
                                            <MultiSelect name="Equipment Type"
                                                         values={equipmentData}
                                                         setChange={setEquipType}
                                                         value={equipType}
                                                         disabled={!destination || false}
                                            />
                                        </Grid>
                                        <Grid mobile={12} tablet={6} full={3}>
                                            <DatePickerTool name="Start Date"
                                                            setChange={setStartDate}
                                                            disabled={equipType?.length<1}
                                                            value={startDate}
                                                            className={'startDate'}
                                            />
                                        </Grid>
                                        <Grid mobile={12} tablet={6} full={3}>
                                            <DatePickerTool name="End Date"
                                                            minDate={startDate}
                                                            setChange={setEndDate}
                                                            disabled={equipType?.length<1}
                                                            value={endDate}/>
                                        </Grid>
                                        <Grid mobile={12} tablet={6} full={3} sx={{textAlign: "right", marginTop: "18px"}}>
                                            <Button
                                                variant="contained"
                                                disabled={isResetDisabled}
                                                startIcon={<RestartAltIcon/>}
                                                onClick={handleReset}
                                                style={{width: '45%', marginRight: '10%'}}
                                                id='reset'
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                disabled={isDisabled}
                                                variant="contained"
                                                startIcon={<FilterIcon/>}
                                                onClick={handleFilterClick}
                                                style={{width: '45%'}}
                                                id='apply'
                                                name={'apply'}
                                            >
                                                Apply
                                            </Button>
                                        </Grid>
                                        {tradelane === 6 &&
                                            <Grid mobile={12} tablet={12} full={12} sx={{textAlign: "left", marginTop: "18px"}}>
                                                <label style={{cursor: 'pointer', fontStyle: 'italic'}}>
                                                    {
                                                        (kffSelected === 'KFF') &&
                                                        <span style={{paddingLeft: '7px'}}>KFF option applied to all equipment selected</span>

                                                    }
                                                    {
                                                        (kffSelected === 'SOME') &&
                                                        <span style={{paddingLeft: '7px'}}>KFF option was applied to some of the equipment selected</span>

                                                    }
                                                    {
                                                        (kffSelected === 'NONE') &&
                                                        <span style={{paddingLeft: '7px'}}>KFF option was not applied to selected equipment</span>

                                                    }
                                                </label>
                                            </Grid>
                                        }

                                    </Grid>
                                </CardContent>
                            </div>
                        </Card>
                    }
                    {
                        filterQueryDateErr &&
                        <div>
                            {filterQueryDateErr}
                        </div>
                    }
                    {
                        !filtersOpen &&
                        <>
                            <Box
                                sx={{
                                    margin: isMobile ? '0' : '0 20px 10px 0',
                                    display: isMobile ? 'inherit' : 'inline-flex',
                                    padding: isMobile? '20px' : '',
                                    '& > :not(style)': {
                                        padding: 0,
                                        margin: 0,
                                    },
                                }}
                            >
                                {isMobile &&
                                    <Paper elevation={3} style={{
                                        margin: '0 15px 20px 0',
                                        borderRadius: '8px', padding: '18px',
                                        fontSize:'16px', minWidth: '100%', height: '120px', marginBottom: '20px'}}>
                                    <span style={{fontWeight: "bold"}}>
                                        {`${getDisplayValuesByID('origins', origin)} - ${getDisplayValuesByID('destinations', destination)}`}
                                    </span>
                                        <br/> {`${format(new Date(startDate), 'MM/dd/yyyy')} - ${format(new Date(endDate), 'MM/dd/yyyy')}`}
                                        <br/> {`Tradelane: ${getDisplayValuesByID('tradelanes', tradelane)}`}
                                        <br/> {`Services: ${getDisplayValuesByID('services', service)}`}
                                    </Paper>
                                }
                                {isTablet &&
                                    <Paper elevation={3} style={{
                                        margin: '0 15px',
                                        borderRadius: '8px', width: '310px', height: '180px',
                                        padding: '18px', fontSize: '14px'}}>
                                    <span style={{fontWeight: "bold"}}>
                                        {`${getDisplayValuesByID('origins', origin)} - ${getDisplayValuesByID('destinations', destination)}`}
                                    </span>
                                        <br/> {`${format(new Date(startDate), 'MM/dd/yyyy')} - ${format(new Date(endDate), 'MM/dd/yyyy')}`}
                                        <br/> {`Tradelane: ${getDisplayValuesByID('tradelanes', tradelane)}`}
                                        <br/> {`Services: ${getDisplayValuesByID('services', service)}`}
                                    </Paper>
                                }
                                {(!isMobile && !isTablet) &&
                                    <Paper elevation={3} style={{
                                        margin: '0 15px 10px 0',
                                        borderRadius: '8px', width: '310px', height: '120px',
                                        padding: '18px', fontSize: '14px'}}>
                                    <span style={{fontWeight: "bold"}}>
                                        {`${getDisplayValuesByID('origins', origin)} - ${getDisplayValuesByID('destinations', destination)}`}
                                    </span>
                                        <br/> {`${format(new Date(startDate), 'MM/dd/yyyy')} - ${format(new Date(endDate), 'MM/dd/yyyy')}`}
                                        <br/> {`Tradelane: ${getDisplayValuesByID('tradelanes', tradelane)}`}
                                        <br/> {`Services: ${getDisplayValuesByID('services', service)}`}
                                    </Paper>
                                }
                                {isMobile && <br/>}
                                <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                                    {costsTableData?.equipmentTypes.map((equip, index) => {
                                        if(userType === 'sales' && !isMobile && !isTablet) {
                                            return (
                                                <Paper elevation={3} style={{borderRadius: '8px', margin: '0 5px 10px 5px', width: '190px', padding: '8px 15px', height: '80px'}} key={index}>
                                                    <Grid container sx={{fontSize: '12px', position: 'relative', top: '0px'}}>
                                                        <Grid xs={6}><span style={{
                                                            fontWeight: "bold",
                                                            fontSize: '18pt',
                                                            color: '#003190',
                                                            textAlign: 'left',
                                                            fontFamily: 'Impact',
                                                        }}>{getVanityEqupNames(equip.equipmentId)}</span></Grid>
                                                        <Grid xs={6}>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{fontSize: '12px', position: 'relative', bottom: '-8px'}}>
                                                        <Grid xs={6} style={{textAlign: 'left'}}>variable costs:</Grid>
                                                        <Grid xs={6} style={{textAlign: 'right'}}>{reformatCostsForDisplay(equip.variableCosts)}</Grid>
                                                    </Grid>
                                                </Paper>
                                            )
                                        }  else if(isMobile) {
                                            return (
                                                <Paper elevation={3} style={{margin: '0 5px 10px 5px', borderRadius: '8px',
                                                    width: '47%', padding: '8px 15px', height: '80px'}} key={index}>
                                                    <Grid container sx={{fontSize: '12px', position: 'relative', top: '0px'}}>
                                                        <Grid mobile={6}><span style={{
                                                            fontWeight: "bold",
                                                            fontSize: '18pt',
                                                            color: '#003190',
                                                            textAlign: 'left',
                                                            fontFamily: 'Impact',
                                                        }}>{getVanityEqupNames(equip.equipmentId)}</span></Grid>
                                                        <Grid mobile={6} style={{fontSize: '14pt', marginLeft: '-10px', fontWeight: 'bold', textAlign: 'right'}}>
                                                            {reformatCostsForDisplay(equip.variableCosts)}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{fontSize: '12px', position: 'relative', bottom: '-8px'}}>
                                                        <Grid xs={6} style={{textAlign: 'left', fontSize: '12pt'}}>variable costs</Grid>
                                                    </Grid>
                                                </Paper>
                                            )
                                        } else if(isTablet) {
                                            return (
                                                <Paper elevation={3} style={{margin: '0 5px 10px 5px', borderRadius: '8px',
                                                    width: '190px', padding: '8px 15px', height: '80px'}} key={index}>
                                                    <Grid container sx={{fontSize: '12px', position: 'relative', top: '0px'}}>
                                                        <Grid mobile={6}><span style={{
                                                            fontWeight: "bold",
                                                            fontSize: '18pt',
                                                            color: '#003190',
                                                            textAlign: 'left',
                                                            fontFamily: 'Impact',
                                                        }}>{getVanityEqupNames(equip.equipmentId)}</span></Grid>
                                                        <Grid mobile={6} style={{fontSize: '16pt', marginLeft: '-10px', fontWeight: 'bold', textAlign: 'right'}}>
                                                            {reformatCostsForDisplay(equip.variableCosts)}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{fontSize: '12px', position: 'relative', bottom: '-8px'}}>
                                                        <Grid xs={6} style={{textAlign: 'left', fontSize: '12pt'}}>variable costs</Grid>
                                                    </Grid>
                                                </Paper>
                                            )
                                        } else {
                                            return (
                                                <Paper elevation={3} style={{borderRadius: '8px', width: '160px', padding: '8px 15px 20px',marginRight: '15px', marginBottom: '10px'}} key={index}>
                                                    <Grid container sx={{fontSize: '12px', position: 'relative', top: '0px'}}>
                                                        <Grid xs={6}><span style={{
                                                            fontWeight: "bold",
                                                            fontSize: '18pt',
                                                            color: '#003190',
                                                            textAlign: 'left',
                                                            fontFamily: 'Impact',
                                                        }}>{getVanityEqupNames(equip.equipmentId)}</span></Grid>
                                                        <Grid xs={6}>
                                                            <PieChart
                                                                series={[
                                                                    { data: [{ value: equip.fixedCosts, color:'#FFD400' }, { value: equip.variableCosts, color:'#FF7E00' }] },
                                                                ]}
                                                                {...{ height: 40, margin: { right: -15 }}}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        equip.fixedCosts > 0 &&
                                                        <Grid container sx={{fontSize: '12px', position: 'relative', bottom: '-8px'}}>
                                                            <Grid xs={6} style={{textAlign: 'left'}}><span style={{color: '#FFD400'}}>◼</span> fixed:</Grid>
                                                            <Grid xs={6} style={{textAlign: 'right'}}>{reformatCostsForDisplay(equip.fixedCosts)}</Grid>
                                                            <Grid xs={6} style={{textAlign: 'left'}}><span style={{color: '#FF7E00'}}>◼</span> variable:</Grid>
                                                            <Grid xs={6} style={{textAlign: 'right'}}>{reformatCostsForDisplay(equip.variableCosts)}</Grid>
                                                            <Grid xs={6} style={{textAlign: 'left'}}><span>◻</span> total:</Grid>
                                                            <Grid xs={6} style={{textAlign: 'right'}}>{reformatCostsForDisplay(equip.costSummary)}</Grid>
                                                        </Grid>
                                                    }
                                                    {
                                                        equip.fixedCosts < 1 &&
                                                        <Grid container sx={{fontSize: '12px', position: 'relative', bottom: isMobile ? '-43px' : null, left: '-2px'}}>
                                                            <Grid xs={6} style={{textAlign: 'left'}}><span>◻</span> total:</Grid>
                                                            <Grid xs={6} style={{textAlign: 'right'}}>{reformatCostsForDisplay(equip.costSummary)}</Grid>
                                                        </Grid>
                                                    }

                                                </Paper>
                                            )
                                        }
                                    })}
                                </div>
                            </Box>
                            {isMobile &&
                                <div style={{textAlign: 'center', width: '100%'}}>
                                    <Button
                                        variant="contained"
                                        startIcon={<FilterAltIcon/>}
                                        onClick={() => setFiltersOpen(!filtersOpen)}
                                        style={{marginTop: '20px', width: '90%'}}
                                    >
                                        Change Query
                                    </Button>
                                </div>
                            }
                        </>
                    }

                    {
                        !isMobile &&
                        <Grid xs={12} sx={{marginTop: '10px'}}>
                            {costsTableData?.costData?.length > 0 && <CostsTable costsTableData={costsTableData}
                                                                                 hasParentID={hasParentID}
                                                                                 setopenRowIdArray={setopenRowIdArray}
                                                                                 openRowIdArray={openRowIdArray}
                                                                                 userType={userType}
                                                                                 setDataView={setDataView}
                                                                                 dataView={dataView}
                                                                                 canUseQuarters={canUseQuarters}
                                                                                 csvData={csvData}
                                                                                 setFiltersOpen={setFiltersOpen}
                                                                                 filtersOpen={filtersOpen}
                                                                                 setSelectedCostType={setSelectedCostType}
                                                                                 selectedCostType={selectedCostType}
                                                                                 origin={origin}
                                                                                 destination={destination}
                                                                                 tradelane={tradelane}
                                                                                 quarterStartDate={quarterStartDate}
                                                                                 quarterEndDate={quarterEndDate} startQuarter={startQuarter} endQuarter={endQuarter}
                            />}
                        </Grid>
                    }
                    {
                        hasParentID &&
                        <Grid xs={12} sx={{marginTop: '10px'}}>
                        * Incomplete Cost Data.  Please contact Pricing Team to receive more accurate and detailed costs data for this destination.
                        </Grid>
                    }
                </React.Fragment>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Card sx={modalStyle}>
                        <CardContent>
                            <div style={{position: 'absolute', top: '10px', right: '-8px'}}><Button size="small" onClick={handleModalClose}>X</Button></div>
                            <div style={{fontWeight: 'bold', fontSize: '18pt'}}>Incomplete Cost Data</div>
                            <div style={{marginTop: '15px'}}>Please contact Pricing Team to receive more accurate and detailed costs data for this destination.</div>
                        </CardContent>
                    </Card>

                </Modal>
            </ThemeProvider>
        );
    }

}

export default CostTool;
